const awsmobile =  {
  api_name: 'chatapp',
  api_paths: {
    users: 'users',
  },
  endpoint: 'https://di1stskaj6.execute-api.ap-northeast-1.amazonaws.com/prod/',
  aws_appsync_graphqlEndpoint: 'https://4sgni3rsibdudiuxyapw7smoc4.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  aws_appsync_region: 'ap-northeast-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-krkwyz4k75bnvpwij2b5aa4b3m',
};

export default awsmobile;
