const { location: l, document: d, navigator: n, innerWidth: w } = window
const serviceName = 'ishoukeikaku_chat'

// get current url
const getProtocol = () => l.protocol==='http:'  ? '1'
                        : l.protocol==='https:' ? '2'
                        : l.protocol
export const getUrl = () => getProtocol() + l.hostname + l.pathname + l.search

export const getBrowser = () => n.userAgent

// set cookie (3 * 1000*60*60*24*365)
export const setCookie = (obj) => {
  const val = JSON.stringify(obj)
  const dt = new Date()
  dt.setTime(dt.getTime() + 94608000000)
  d.cookie = `${serviceName}=${val};expires=${dt.toUTCString()}`
  localStorage.setItem(serviceName, JSON.stringify(obj))
}

// get cookie
export const getCookie = () => {
  var m = d.cookie.match(`(^|;)\\s*${serviceName}\\s*=\\s*([^;]+)`)
  const cookie = m ? m.pop() : ''
  if (cookie) {
    console.log('cookie', cookie)
    return cookie
  }
  return JSON.parse(localStorage.getItem(serviceName))
}
