import { version } from '../package.json'
import * as widget from './widget'
import * as data from './data'
import * as browser from './browser'

let subscriptionResult, nameBox, inputBox
let _myid = 'ANON'
let _apik = '9bf150d0-531c-49de-9993-0a02b80c32f2-8bbce3de-f657-4d0c-adcd-e6c742df504a'
let _nick = '_'

const HEARTBEAT_FREQ = 60000
const IDS = {
  INPUT_BOX: 'ChWidgetChTxt2020-1',
  SETNAME_BOX: 'ChWidgetChTxtName',
  MSG_RES:   'SubscriptionResult',
}

/**
 * onClick for send message
 */
const onSendMessage = () => {
  if (!inputBox) inputBox = document.getElementById(IDS.INPUT_BOX)
  if (!inputBox || !inputBox.value) {
    console.log('nothing to send')
    return
  }
  if (!_apik) {
    console.log('not authenticated')
    return
  }
  data.sendMessage(_myid, _myid, _apik, inputBox.value)
    .then(() => { inputBox.value = ''; })
    .catch(err => console.log('seuser status sentnd message err', err.errors))
}

/**
 * onClick for set guest id
 */
const onSetName = () => {
  if (!nameBox) nameBox = document.getElementById(IDS.SETNAME_BOX)
  if (!nameBox || !nameBox.value) {
    console.log('nothing to set!')
    return
  }
  if (!_apik) {
    console.log('not authenticated')
    return
  }
  _nick = nameBox.value
  data.updateUserStatus(_myid, _nick)
    .then(resp => console.log(`hello ${_nick}!`, resp))
    .catch(err => console.log('user status err', err))
}

/** Send user status every X seconds */
const sendUserStatus = () => {
  const delayRepeat = () => {
    setTimeout(() => {
      sendUserStatus()
    }, HEARTBEAT_FREQ)
  }

  if (_nick && _apik) {
    data.updateUserStatus(_myid, _nick)
    .then(() => console.log('user status sent', _myid))
    .catch(err => console.log('user status err', err))
    .finally(() => delayRepeat())
  } else {
    delayRepeat()
  }
}

const subscribeToMyMsgs = () =>
  data.subscribeToMessages(
    _myid,
    (evt) => {
      if (!subscriptionResult) {
        subscriptionResult = document.getElementById(IDS.MSG_RES);
      }
      // console.log('evt.value.data', evt)
      const msg = evt.value.data.createMessageForMe
      const from = msg.from === _myid ? 'me' : msg.from
      const textColor = msg.from === _myid ? '#1890ff' : '#ff2318'
      // const ts = new Date(msg.at*1000).toLocaleTimeString()
      const ts = new Date().toLocaleTimeString()
      subscriptionResult.innerHTML += `
      <p>
        <span style="color:${textColor};">${from}</span>
        <span style="font-size:.8em;">${ts}</span>
        <span> - ${msg.body}</span>
      </p>`;
      setTimeout(() => {
        subscriptionResult.scrollTop = subscriptionResult.scrollHeight
      }, 500)
    },
  )

const processWidget = (url, brz) => {
  // checks cookies to see if there's a user, if no user found, create new one
  // console.log('process widget')
  try {
    const cookieData = browser.getCookie()
    if (cookieData && cookieData.id) _myid = cookieData.id
  } catch (ex) { console.error('cookie error', ex)}

  // create new user if no cookie
  if (!_myid || _myid === 'ANON' || !_apik) {
    data.createNewUser(brz, _apik, url)
      .then(resp => {
        const newUser = resp.data.createGuest
        _myid = newUser.id
        browser.setCookie({id:_myid})
        sendUserStatus()
        subscribeToMyMsgs()
      })
      .catch(err => console.log('create user err', err))
  } else {
    sendUserStatus()
    subscribeToMyMsgs()
  }

  // console.log({ _myid, _apik })
  if (_myid && _apik) {
    widget.showChatButton(onSetName, onSendMessage)
    setTimeout(() => {
      widget.enterWidget()
    }, 2000)
  }
}

// Start
window.onload = () => {

  let url = browser.getUrl()
  let brz = browser.getBrowser()

  // checks to see if anyone's online and get apik
  const onSuccess = res => {
    if (res.status === 200) {
      res.json()
        .then(o => {
          // console.log('online', o.users)
          _apik = o.apik
          if (o.users && o.users.length) {
            processWidget(url, brz)
          }
          console.log(`意匠計画 Chat Widget V${version} 👍`)
        })
    } else {
      console.log(`意匠計画 Chat Widget V${version} 🚫`)
    }
  }

  try {
    data.checkWhosOn(_apik, url, brz)
      .then(onSuccess)
      .catch(err => console.error('api error', err))
  } catch {}
}
