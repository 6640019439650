/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGuest = /* GraphQL */ `
  mutation CreateGuest($browser: String!, $apik: String!, $url: String!) {
    createGuest(browser: $browser, apik: $apik, url: $url) {
      id
      apik
      nick
      url
      browser
      lastAt
      createdAt
    }
  }
`;
export const updateGuest = /* GraphQL */ `
  mutation UpdateGuest($id: String!, $nick: String!) {
    updateGuest(id: $id, nick: $nick) {
      id
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $id: String!
    $from: String!
    $apik: String!
    $body: String!
  ) {
    createMessage(id: $id, from: $from, apik: $apik, body: $body) {
      id
      apik
      from
      body
    }
  }
`;
