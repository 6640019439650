import Amplify, { API, graphqlOperation } from "@aws-amplify/api"
import awsconfig from "./aws-exports"
import * as mut from "./graphql/mutations"
import * as sub from "./graphql/subscriptions"

Amplify.configure(awsconfig)

// Get Messages

// export async function getCurrentAdminUsers(cb) {
//   API.graphql(graphqlOperation(getAdminUsers, '')).then((evt) => {
//     evt.data.getMessages.map(cb);
//   });
// }

// GraphQL Mutations

export async function sendMessage(id, from, apik, body) {
  return await API.graphql(graphqlOperation(mut.createMessage, {id, from, apik, body}))
}

export async function createNewUser(browser, apik, url) {
  return await API.graphql(graphqlOperation(mut.createGuest, {browser, apik, url}))
}

export async function updateUserStatus(id, nick) {
  return await API.graphql(graphqlOperation(mut.updateGuest, {id, nick}))
}

export async function checkWhosOn(apik, url, brz) {
  const request = new Request(
    `${awsconfig.endpoint}${awsconfig.api_paths.users}`,
    {
      method: 'POST',
      body: JSON.stringify({ apik, url, brz }),
    },
  )
  return fetch(request)
}

// Subscriptions

export const subscribeToMessages = (id, cb) => {
  API.graphql(graphqlOperation(sub.createMessageForMe, { id })).subscribe({
    next: cb,
  });
}
