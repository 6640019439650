/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessageForMe = /* GraphQL */ `
  subscription CreateMessageForMe($id: String!) {
    createMessageForMe(id: $id) {
      id
      # at  # bug? this doesnt get returned from result
      # apik
      from
      body
    }
  }
`;