const styles = {
  font: '-apple-system, BlinkMacSystemFont, "segoe ui", Roboto, "helvetica neue", Arial, "noto sans", sans-serif',
  size: '.85em',
  width: '150px',
  outerWidth: '160px',
  height: '300px',
  blue: '#1890ff',
  green: '#33db30',
  white: '#fff',
  startPos: -155,
  endPos: 0,
  animationDelay: 1,
}

const applyStyles = (o, sty) => {
  for (let k of Object.keys(sty))
    o.style[k] = sty[k]
}

var mainDiv = document.createElement('div')
mainDiv.style.position = 'fixed'
mainDiv.style.bottom = `${styles.startPos}px`
mainDiv.style.right = '10px'
mainDiv.style.width = styles.outerWidth
mainDiv.style.fontSize = styles.size
mainDiv.style.zIndex = 99999
document.body.appendChild(mainDiv)

export const showChatButton = (setNameEvent, sendMsgEvent) => {
  const chatButton = document.createElement('button')
  chatButton.id = 'ChWidgetChBtn2020-1'
  applyStyles(chatButton, {
    width: '150px',
    height: '150px',
    backgroundImage: 'url("https://swifware.com/chaticon.png")',
    backgroundSize: 'contain',
    border: 'none',
    backgroundColor: 'transparent',
    width: styles.width,
    fontFamily: styles.font,
    cursor: 'pointer',
  })

  chatButton.addEventListener('click', () => showChatBox(setNameEvent, sendMsgEvent))
  mainDiv.appendChild(chatButton)
}

export const showSetNameBox = (onSetName) => {
  const container = document.createElement('div')
  mainDiv.appendChild(container)
  
  const textarea = document.createElement('textarea')
  textarea.id = 'ChWidgetChTxtName'
  textarea.placeholder = 'お名前を入力してください'
  applyStyles(textarea, {
    width: styles.width,
  })
  
  const submit = document.createElement('button')
  submit.innerHTML = '送信'
  submit.id = 'ChWidgetChBtnName'
  applyStyles(submit, {
    width: styles.width,
    height: '30px',
    borderRadius: '3px',
    backgroundColor: styles.green,
    color: styles.white,
    border: 'none',
    textShadow: '0 2px 0 rgba(0,0,0,.045)',
    cursor: 'pointer',
  })
  submit.addEventListener('click', onSetName)

  container.appendChild(textarea)
  container.appendChild(submit)

  var showBtn = document.getElementById('ChWidgetChBtn2020-1');
  showBtn.parentNode.removeChild(showBtn);
}

export const showChatBox = (setNameEvent, sendMsgEvent) => {

  const callback = () => {
    setNameEvent()

    const container = document.createElement('div')
    mainDiv.appendChild(container)

    const list = document.createElement('div')
    applyStyles(list, {
      maxHeight: '200px',
      backgroundColor: 'white',
      overflowX: 'hidden',
      overflowY: 'auto',
    })
    list.id = 'SubscriptionResult'

    const textarea = document.createElement('textarea')
    textarea.id = 'ChWidgetChTxt2020-1'
    textarea.placeholder = 'メッセージをください'
    textarea.required = true
    applyStyles(textarea, {
      width: styles.width,
    })

    const submit = document.createElement('button')
    submit.innerHTML = '送信'
    applyStyles(submit, {
      width: styles.width,
      height: '30px',
      borderRadius: '3px',
      backgroundColor: styles.blue,
      color: styles.white,
      border: 'none',
      textShadow: '0 2px 0 rgba(0,0,0,.045)',
      cursor: 'pointer',
    })
    submit.addEventListener('click', sendMsgEvent)

    // container.appendChild(title)
    container.appendChild(list)
    container.appendChild(textarea)
    container.appendChild(submit)

    var nameBtn = document.getElementById('ChWidgetChBtnName');
    if (nameBtn) nameBtn.parentNode.removeChild(nameBtn);
    var nameTxt = document.getElementById('ChWidgetChTxtName');
    if (nameTxt) nameTxt.parentNode.removeChild(nameTxt);
  }

  showSetNameBox(callback)
}

export const enterWidget = () => {
  let pos = parseInt(mainDiv.style.bottom.replace('px',''))
  const moveUp = () => {
    setTimeout(() => {
      pos += 2
      mainDiv.style.bottom = `${pos}px`
      if (pos < styles.endPos) {
        moveUp()
      }
    }, styles.animationDelay)
  }
  moveUp()
}
